/**
 * Subscription API
 * API to retrieve subscription details by subscriptionID.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SelectRealmResponse { 
    status?: SelectRealmResponse.StatusEnum;
    token?: string;
    url?: Array<string>;
}
export namespace SelectRealmResponse {
    export type StatusEnum = 'OK' | 'ERROR';
    export const StatusEnum = {
        Ok: 'OK' as StatusEnum,
        Error: 'ERROR' as StatusEnum
    };
}


